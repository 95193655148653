<template>
  <!-- 左侧和右边的内容 -->
  <!-- :class="{ isHiden: isHide }" -->
  <div class="NavigationBar" :class="{ isHiden: isHide }">
    <!-- 公众号二维码 -->
    <div class="notice-box" v-if="showUs">
      <div class="notice-content">
        <!-- 关闭按钮 -->
        <div class="close-btn">
          <el-icon size="2rem" style="color: white" @click="showUs = false">
            <CloseBold />
          </el-icon>
        </div>
        <p class="notice-title">微信扫码关注公众号</p>
        <img class="wxgzh-img" src="../assets/wxgzh.png" />
      </div>
    </div>
    <!-- 显示侧边栏按钮 -->
    <div class="ShowBtn" @click="ToggleHideLeftBar()" v-if="isHide">
      <el-icon>
        <Expand />
      </el-icon>
    </div>
    <!-- 隐藏侧边栏按钮 -->
    <div class="hideBtn" @click="ToggleHideLeftBar()" v-if="!isHide">
      <el-icon>
        <Fold />
      </el-icon>
    </div>
    <!-- 侧边栏 -->
    <div class="leftNavigation">
      <el-avatar
        class="headPortrait hidden"
        :size="70"
        :icon="UserFilled"
        :src="
          store.getters.userinfo.avatar
            ? imageUrl + store.getters.userinfo.avatar
            : require('../assets/logoHead.svg')
        "
      />
      <!-- :style="{ visibility: store.getters.userinfo ? 'visible' : 'hidden' }" -->
      <div class="surplus">
        <!-- 消息提醒 -->
        <div class="wrapper" style="visibility: hidden">
          <div class="swing">
            <el-icon size="2rem" color="white">
              <Bell />
            </el-icon>
          </div>
        </div>
        <div
          class="surplus-title"
          v-on:mouseover="hideSurplus = false"
          v-if="hideSurplus"
        >
          剩余Token
        </div>
        <div
          class="surplus-title"
          v-on:mouseout="hideSurplus = true"
          v-if="!hideSurplus"
        >
          <div>{{ userinfo.frequency }}</div>
          <el-icon size="1rem">
            <Coin />
          </el-icon>
        </div>
        <!-- 消息提醒 -->
        <div class="wrapper">
          <div class="swing">
            <el-icon size="2rem" color="white">
              <Bell />
            </el-icon>
          </div>
        </div>
      </div>
      <div class="header">
        <!-- 侧边栏选项模块 -->
        <div class="switch-button">
          <router-link
            v-for="(item, index) in navigationList"
            :key="index"
            active-class="switch-active"
            class="switch-item"
            :to="item.to"
          >
            <!-- 显示图标 -->
            <el-icon size="1.25rem">
              <component :is="item.icon" />
            </el-icon>
            <div class="switch-item-title">{{ item.title }}</div>
          </router-link>
        </div>
      </div>
      <div class="newchatbox">
        <!-- 创建新的对话 -->
        <div
          class="cache-btn"
          @click="AddNewDialogue()"
          v-if="
            (router.currentRoute.value.path == '/' ||
              router.currentRoute.value.path == '/Orders' ||
              router.currentRoute.value.path == '/price' ||
              router.currentRoute.value.path == '/collection' ||
              router.currentRoute.value.path == '/purchase' ||
              router.currentRoute.value.path == '/indicator') &&
            store.getters.userinfo
          "
        >
          <el-icon size="1.25rem">
            <ChatLineSquare />
          </el-icon>
          <div class="cache-btn-text">创建新的聊天</div>
        </div>
        <!-- 创建新的画册 -->
        <div
          class="cache-btn"
          @click="AddNewAlbum()"
          v-if="
            router.currentRoute.value.path == '/picture' &&
            store.getters.userinfo
          "
        >
          <el-icon size="1.25rem">
            <PictureRounded />
          </el-icon>
          <div class="cache-btn-text">创建新的画册</div>
        </div>
      </div>
      <!-- 聊天框列表 -->
      <div
        class="cache-content"
        v-if="
          router.currentRoute.value.path == '/' ||
          router.currentRoute.value.path == '/Orders' ||
          router.currentRoute.value.path == '/price' ||
          router.currentRoute.value.path == '/collection' ||
          router.currentRoute.value.path == '/purchase' ||
          router.currentRoute.value.path == '/indicator'
        "
      >
        <div class="cache-scrollbar">
          <el-scrollbar height="100%">
            <!-- 实现选中的对话颜色不一样 -->
            <div
              class="cache-padding"
              v-for="(item, index) in dialogueCache.array"
              :key="index"
              :style="{
                backgroundColor:
                  dialogueCache.index == item.ID ? '#464646' : '#1D2022',
              }"
            >
              <div class="cache-padding-item">
                <!-- 消息标题 -->
                <div
                  class="cache-message"
                  @click="
                    isEditing == true ? Nothing() : switchChat(item.ID, 1)
                  "
                >
                  <!-- 对话框标题可修改 -->
                  <div
                    class="cache-message-text"
                    v-show="
                      !(dialogueCache.index == item.ID ? true : false) ||
                      !EditVisible
                    "
                  >
                    {{ item.title }}
                  </div>
                  <input
                    class="cache-message-input"
                    type="text"
                    v-model="MsgTitle"
                    v-show="
                      (dialogueCache.index == item.ID ? true : false) &&
                      EditVisible
                    "
                    placeholder="请输入标题"
                    @keyup.enter="
                      (EditVisible = false),
                        (isEditing = false),
                        TitleChange(index, 1, item.ID)
                    "
                  />
                  <div class="cache-message-time">
                    {{ conversionTime(item.time) }}
                  </div>
                </div>
                <!-- 右边的按钮-->
                <div class="cache-selected">
                  <!-- 不在编辑状态 -->
                  <div class="No-edit" v-show="!EditVisible">
                    <!-- 点击编辑按钮进入编辑模式 -->
                    <div
                      class="Edit-btn"
                      @click="(EditVisible = true), (isEditing = true)"
                      :style="{
                        visibility:
                          dialogueCache.index == item.ID ? 'visible' : 'hidden',
                      }"
                    >
                      <el-icon color="#FFFFFFF" size="1.25rem">
                        <EditPen />
                      </el-icon>
                    </div>
                    <!-- 删除按钮-->
                    <div class="Del-btn" @click="DelDialogue(item.ID)">
                      <el-icon color="#FFFFFF" size="1.25rem">
                        <Delete />
                      </el-icon>
                    </div>
                  </div>
                  <!-- 编辑状态 -->
                  <div
                    class="In-edit"
                    v-show="EditVisible"
                    :style="{
                      visibility:
                        dialogueCache.index == item.ID ? 'visible' : 'hidden',
                    }"
                  >
                    <!-- 确认按钮 -->
                    <div
                      class="Edit-btn"
                      @click="
                        (EditVisible = false),
                          (isEditing = false),
                          TitleChange(index, 1, item.ID)
                      "
                    >
                      <el-icon color="#FFFFFF" size="1.25rem">
                        <Check />
                      </el-icon>
                    </div>
                    <!-- 取消编辑 -->
                    <div
                      class="Del-btn"
                      @click="(EditVisible = false), (isEditing = false)"
                    >
                      <el-icon color="#FFFFFF" size="1.25rem">
                        <Close />
                      </el-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <!-- 画册列表 -->
      <div
        class="cache-content"
        v-if="router.currentRoute.value.path == '/picture'"
      >
        <div class="cache-scrollbar">
          <el-scrollbar height="100%">
            <!-- 实现选中的对话颜色不一样 -->
            <div
              class="cache-padding"
              v-for="(item, index) in imageCache.array"
              :key="index"
              :style="{
                backgroundColor:
                  imageCache.index === item.ID ? '#464646' : '#1D2022',
              }"
            >
              <div class="cache-padding-item">
                <!-- 消息标题 -->
                <div
                  class="cache-message"
                  @click="
                    isEditing == true ? Nothing() : switchChat(item.ID, 2)
                  "
                >
                  <!-- 对话框标题可修改 -->
                  <div
                    class="cache-message-text"
                    v-show="
                      !(imageCache.index == item.ID ? true : false) ||
                      !EditVisible
                    "
                  >
                    {{ item.title }}
                  </div>
                  <input
                    class="cache-message-input"
                    type="text"
                    v-model="MsgTitle"
                    v-show="
                      (imageCache.index == item.ID ? true : false) &&
                      EditVisible
                    "
                    placeholder="请输入标题"
                    @keyup.enter="
                      (EditVisible = false),
                        (isEditing = false),
                        TitleChange(index, 2, item.ID)
                    "
                  />
                  <div class="cache-message-time">
                    {{ conversionTime(item.time) }}
                  </div>
                </div>
                <!-- 右边的按钮-->
                <div class="cache-selected">
                  <!-- 不在编辑状态 -->
                  <div class="No-edit" v-show="!EditVisible">
                    <!-- 点击编辑按钮进入编辑模式 -->
                    <div
                      class="Edit-btn"
                      @click="(EditVisible = true), (isEditing = true)"
                      :style="{
                        visibility:
                          imageCache.index === item.ID ? 'visible' : 'hidden',
                      }"
                    >
                      <el-icon color="#FFFFFFF" size="1.25rem">
                        <EditPen />
                      </el-icon>
                    </div>
                    <!-- 删除按钮 -->
                    <div class="Del-btn" @click="DelAlbum(item.ID)">
                      <el-icon color="#FFFFFF" size="1.25rem">
                        <Delete />
                      </el-icon>
                    </div>
                  </div>
                  <!-- 编辑状态 -->
                  <div
                    class="In-edit"
                    v-show="EditVisible"
                    :style="{
                      visibility:
                        imageCache.index === item.ID ? 'visible' : 'hidden',
                    }"
                  >
                    <!-- 确认按钮 -->
                    <div
                      class="Edit-btn"
                      @click="
                        (EditVisible = false),
                          (isEditing = false),
                          TitleChange(index, 2, item.ID)
                      "
                    >
                      <el-icon color="#FFFFFF" size="1.25rem">
                        <Check />
                      </el-icon>
                    </div>
                    <!-- 取消编辑 -->
                    <div
                      class="Del-btn"
                      @click="(EditVisible = false), (isEditing = false)"
                    >
                      <el-icon color="#FFFFFF" size="1.25rem">
                        <Close />
                      </el-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <!-- AI语音列表	 -->
      <div
        class="cache-content"
        v-if="router.currentRoute.value.path == '/tts'"
      ></div>
      <!-- AI学术列表 -->
      <div
        class="cache-content"
        v-if="router.currentRoute.value.path == '/academic'"
      >
        <AcademicBar></AcademicBar>
      </div>
      <!-- AI助理列表 -->
      <div
        class="cache-content"
        v-if="router.currentRoute.value.path == '/assistant'"
      ></div>
      <div
        class="cache-content"
        v-if="router.currentRoute.value.path == '/Admin'"
      ></div>
      <div
        class="cache-content"
        v-if="router.currentRoute.value.path == '/news'"
      ></div>
      <div
        class="cache-content"
        v-if="router.currentRoute.value.path == '/prompt'"
      ></div>
      <!-- 底部功能栏 -->
      <div class="Bottom-func">
        <div
          class="bottom-func-menu"
          v-if="hideBottomFunc && store.getters.userinfo"
        >
          <div class="bottom-func-btn" @click="router.push({ path: '/price' })">
            <el-icon size="1.25rem">
              <Notification />
            </el-icon>
            <div>网站说明和计费标准</div>
          </div>
          <div
            class="bottom-func-btn"
            @click="router.push({ path: '/purchase' })"
          >
            <el-icon size="1.25rem">
              <Goods />
            </el-icon>
            <div>购买</div>
          </div>
          <div class="bottom-func-btn">
            <el-icon size="1.25rem" style="color: #ffffff">
              <Brush />
            </el-icon>
            <div>更换背景</div>
            <div class="Color-btn" @click="ColorChange1">W</div>
            <div class="Color-btn" @click="ColorChange2">G</div>
            <div class="Color-btn" @click="ColorChange3">B</div>
          </div>
          <div class="bottom-func-btn" @click="showUs = true">
            <el-icon size="1.25rem">
              <Phone />
            </el-icon>
            <div style="font-size: 1rem">联系客服</div>
          </div>
          <div
            class="bottom-func-btn"
            @click="router.push({ path: '/prompt' })"
            v-if="store.getters.userinfo.type === 'ADMIN'"
          >
            <el-icon size="1.25rem">
              <MagicStick />
            </el-icon>
            <div>提示词</div>
          </div>
          <div
            class="bottom-func-btn"
            @click="router.push({ path: '/Admin' })"
            v-if="store.getters.userinfo.type === 'ADMIN'"
          >
            <el-icon size="1.25rem">
              <Odometer />
            </el-icon>
            <div>控制台</div>
          </div>
          <div class="bottom-func-btn" @click="logout">
            <el-icon size="1.25rem">
              <ArrowLeftBold />
            </el-icon>
            <div>退出登录</div>
          </div>
        </div>
        <!-- 登录按钮 -->
        <div class="login-func">
          <!-- 登陆后的头像 -->
          <div
            class="header-user"
            v-if="store.getters.userinfo"
            @click="hideBottomFunc = !hideBottomFunc"
          >
            <el-avatar
              :size="30"
              :icon="UserFilled"
              :src="
                store.getters.userinfo.avatar
                  ? imageUrl + store.getters.userinfo.avatar
                  : require('../assets/AIHead.png')
              "
            />
            <div>
              {{
                store.getters.userinfo.userName
                  ? store.getters.userinfo.userName
                  : "用户"
              }}
            </div>
          </div>
          <!-- 登录按钮 -->
          <div v-else @click="dialogVisible = true" class="bottom-func-btn">
            <el-icon size="1.25rem">
              <User />
            </el-icon>
            <div>登录</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-wrap" v-if="!isHide" @click="isHide = !isHide"></div>
    <!-- 通过路由改变右边的界面 -->
    <!-- :style="{ width: rightWidth + '%' }" -->
    <div class="rightContent" v-loading="loading">
      <RouterView v-slot="{ Component }">
        <!-- TODO 要缓存 -->
        <KeepAlive>
          <component
            :is="Component"
            :key="$route.name"
            v-if="$route.meta.keepAlive"
          ></component>
        </KeepAlive>
        <!-- TODO 不缓存 -->
        <component
          :is="Component"
          :key="$route.name"
          v-if="!$route.meta.keepAlive"
        ></component>
      </RouterView>
    </div>
  </div>
  <LoginDialog :show="dialogVisible" @close="dialogVisible = false" />
</template>

<script setup>
import { onMounted, ref, watch, onBeforeUnmount, computed } from "vue";
import { useRouter } from "vue-router";
import { EditPen, Odometer, UserFilled } from "@element-plus/icons-vue";
import LoginDialog from "@/components/LoginDialog.vue";
import AcademicBar from "@/components/AcademicBar.vue";

import { conversionTime } from "../utils/date";
import { useStore } from "vuex";
import { Logout } from "../../api/BSideApi";
import { ElLoading, ElNotification } from "element-plus";
import emitter from "../plugins/Bus.js";
import {
  dialogueCache,
  conversationList,
  imageCache,
  ImageConversationList,
} from "../store/store.js";
import axios from "axios";

let userinfo = computed(() => store.getters.userinfo);

// 侧边栏隐藏
let rightWidth = ref(85);
let isHide = ref(false);
const ToggleHideLeftBar = () => {
  if (isHide.value) {
    rightWidth.value = 85;
  } else {
    rightWidth.value = 100;
  }
  isHide.value = !isHide.value;
};

let showUs = ref(false);

let loading = ref(false);
let hideBottomFunc = ref(false);
let hideSurplus = ref(true);
//发送颜色代码
let colorcode1 = ref("#dddfe2"); //灰白色
let colorcode2 = ref("#4f6943"); //绿色
let colorcode3 = ref("#8b8c8d"); //灰色
const ColorChange1 = () => {
  emitter.emit("colorcode", colorcode1);
};
const ColorChange2 = () => {
  emitter.emit("colorcode", colorcode2);
};
const ColorChange3 = () => {
  emitter.emit("colorcode", colorcode3);
};

let store = useStore();
let loginVisible2 = ref(false);

// 对话标题可修改
let MsgTitle = ref("");
let EditVisible = ref(false);
let isEditing = ref(false);

// 路由组件
let router = useRouter();

let dialogVisible = ref(false);
let isHeadNavigation = ref(false);
const navigationList = ref([
  {
    title: "AI问答",
    icon: "ChatDotSquare",
    to: "/",
  },
  {
    title: "AI绘图",
    icon: "Picture",
    to: "/picture",
  },
  // {
  //   title: "AI语音",
  //   icon: "Microphone",
  //   to: "/tts",
  // },
  // {
  //   title: "代码解释器",
  //   icon: "Reading",
  //   to: "/CodeInterpreter",
  // },
  {
    title: "AI学术",
    icon: "Reading",
    to: "/academic",
  },
]);

//监听路由变化
watch(
  () => router.currentRoute.value,
  (newValue) => {
    isHeadNavigation.value = newValue.meta.isHeadNavigation;
  },
  {
    immediate: true,
  }
);
const imageUrl = ref("");
//页面初次渲染
onMounted(() => {
  imageUrl.value = process.env.VUE_APP_IMAGE;
  //获取云端对话缓存数据
  if (store.getters.userinfo) {
    if (!store.getters.userinfo) return (loginVisible2.value = true);
    // 获取用户所有对话
    GetUserAllDialogueCache();
  }
  // 手机检测
  CheckIfMobile();
  window.addEventListener("resize", CheckIfMobile());
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", CheckIfMobile());
});
// 手机检测
function CheckIfMobile() {
  isHide.value = window.innerWidth <= 450; // 你可以根据需要调整阈值
}

// 获取用户所有对话缓存
async function GetUserAllDialogueCache() {
  try {
    const data = {
      userID: JSON.parse(localStorage.getItem("userInfo")).userID,
    };
    const response = await axios.post(
      `${process.env.VUE_APP_HK_NODE_BASE_URL}/admin/chat/GetUserAllDialogueCache`,
      data
    );
    // 将数据赋值给本地变量
    dialogueCache.value.array = response.data;
    if (dialogueCache.value.array.length == 0) {
      ElNotification({
        message: "你没有任何对话数据",
        type: "info",
      });
    }
  } catch (error) {
    console.log(error);
    ElNotification({
      message: "获取数据失败",
      type: "error",
    });
  }
}

// 新建对话
async function AddNewDialogue() {
  try {
    // 清空当前对话列表
    conversationList.value = [];
    // 创建时间
    const data = {
      userID: JSON.parse(localStorage.getItem("userInfo")).userID,
      time: Date.now(),
    };
    const response = await axios.post(
      `${process.env.VUE_APP_HK_NODE_BASE_URL}/admin/chat/AddNewDialogue`,
      data
    );
    // 如果原来没有任何对话，则初始化结构
    if (dialogueCache.value.array.length == 0) {
      dialogueCache.value = {
        index: null,
        array: [
          {
            ID: response.data.insertId,
            title: "新创建的对话",
            time: data.time,
            context: [],
          },
        ],
      };
      console.log(dialogueCache.value.array);
    } else {
      dialogueCache.value.array.unshift({
        ID: response.data.insertId,
        title: "新创建的对话",
        time: data.time,
        context: [],
      });
      console.log(dialogueCache.value.array);
    }
    // 选中新建的对话
    dialogueCache.value.index = response.data.insertId;
  } catch (error) {
    console.log(error);
    ElNotification({
      message: "新建对话失败",
      type: "error",
    });
  }
}
// 新建画册
async function AddNewAlbum() {
  try {
    // 清空当前对话列表
    ImageConversationList.value = [];
    // 创建时间
    const data = {
      userID: JSON.parse(localStorage.getItem("userInfo")).userID,
      time: Date.now(),
    };
    const response = await axios.post(
      `${process.env.VUE_APP_HK_NODE_BASE_URL}/admin/image/AddNewAlbum`,
      data
    );
    // 如果原来没有任何对话，则初始化结构
    if (imageCache.value.array.length == 0) {
      imageCache.value = {
        index: null,
        array: [
          {
            ID: response.data.insertId,
            title: "新创建的画册",
            time: data.time,
            context: [],
          },
        ],
      };
      console.log("初始化数据：", imageCache.value.array);
    } else {
      imageCache.value.array.unshift({
        ID: response.data.insertId,
        title: "新创建的画册",
        time: data.time,
        context: [],
      });
      console.log("新建的数据：", imageCache.value.array);
    }
    // 选中新建的对话
    imageCache.value.index = response.data.insertId;
  } catch (error) {
    console.error(error);
    ElNotification({
      message: "新建画册失败",
      type: "error",
    });
  }
}

async function logout() {
  try {
    // 显示加载图标
    ElLoading.service({
      fullscreen: true,
      text: "正在退出登录...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    await Logout();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    location.reload();
    // 关闭加载图标
    ElLoading.service().close();
    // 弹出退出登录成功提示框
    ElNotification({
      message: "退出登录成功",
      type: "success",
    });
  } catch (e) {
    // 关闭加载图标
    ElLoading.service().close();
    ElNotification({
      message: e,
      type: "error",
    });
  }
}

// TODO 切换对话/画册
async function switchChat(dataID, func) {
  if (func == 1) {
    try {
      loading.value = true;
      const data = {
        dialogueID: dataID,
      };
      const response = await axios.post(
        `${process.env.VUE_APP_HK_NODE_BASE_URL}/admin/chat/GetDialogueMessage`,
        data
      );
      conversationList.value = response.data;
      console.log(conversationList.value);
      // 将点击的对话的index赋值给对话缓存的索引值（当前对话的ID）
      dialogueCache.value.index = dataID;
      // 将对应的对话数据保存在conversationList数组中，用于在右边显示对应项目的聊天内容
      if (router.currentRoute.value != "/") {
        router.push("/");
      }
      loading.value = false;
    } catch (error) {
      console.error(error);
      loading.value = false;
    }
  } else if (func == 2) {
    try {
      loading.value = true;
      const data = {
        albumID: dataID,
      };
      const response = await axios.post(
        `${process.env.VUE_APP_HK_NODE_BASE_URL}/admin/image/GetImageMessage`,
        data
      );
      console.log(response.data);
      ImageConversationList.value = response.data;
      // 将点击的对话的index赋值给对话缓存的索引值（当前对话的ID）
      imageCache.value.index = dataID;
      loading.value = false;
    } catch (error) {
      console.error(error);
      loading.value = false;
    }
  }
}

// 空事件
function Nothing() {}

// 改变当前点击对象的标题，通过index来确定
async function TitleChange(index, func, dataID) {
  try {
    // 修改对话标题
    if (func == 1) {
      // 如果没有输入任何数据，就是默认标题
      if (MsgTitle.value == "") {
        MsgTitle.value = dialogueCache.value.array[index].title;
      } else {
        // 如果输入了数据，就将输入的数据保存为标题
        const data = {
          dialogueID: dataID,
          title: MsgTitle.value,
        };
        await axios.post(
          `${process.env.VUE_APP_HK_NODE_BASE_URL}/admin/chat/ChangeDialogueTitle`,
          data
        );
        // 更新视图
        dialogueCache.value.array[index].title = MsgTitle.value;
      }
    }
    // 修改画册标题
    else if (func == 2) {
      // 如果没有输入任何数据，就是默认标题
      if (MsgTitle.value == "") {
        MsgTitle.value = imageCache.value.array[index].title;
      } else {
        // 如果输入了数据，就将输入的数据保存为标题
        const data = {
          albumID: dataID,
          title: MsgTitle.value,
        };
        await axios.post(
          `${process.env.VUE_APP_HK_NODE_BASE_URL}/admin/image/ChangeAlbumTitle`,
          data
        );
        // 更新视图
        imageCache.value.array[index].title = MsgTitle.value; // 如果输入了数据，就将输入的数据保存为标题
      }
    }
    // 还原
    MsgTitle.value = "";
  } catch (error) {
    console.log(error);
  }
}

// 删除指定对话
async function DelDialogue(dataID) {
  try {
    const data = {
      dialogueID: dataID,
    };
    console.log("删除的ID为", data.dialogueID);
    await axios.post(
      `${process.env.VUE_APP_HK_NODE_BASE_URL}/admin/chat/DelDialogue`,
      data
    );
    // 更新视图
    dialogueCache.value.array = dialogueCache.value.array.filter(
      (item) => item.ID !== dataID
    );
    // 如果删除自己，则不指向任何对话
    if (dialogueCache.value.index == dataID) {
      dialogueCache.value.index = null;
      // 清除对话列表，更新视图
      conversationList.value = [];
    }
  } catch (error) {
    console.log(error);
  }
}
// 删除指定画册
async function DelAlbum(dataID) {
  try {
    const data = {
      albumID: dataID,
    };
    console.log("删除的ID为", data.albumID);
    await axios.post(
      `${process.env.VUE_APP_HK_NODE_BASE_URL}/admin/image/DelAlbum`,
      data
    );
    // 更新视图
    imageCache.value.array = imageCache.value.array.filter(
      (item) => item.ID !== dataID
    );
    // 如果删除自己，则不指向任何对话
    if (imageCache.value.index == dataID) {
      imageCache.value.index = null;
      // 清除对话列表，更新视图
      ImageConversationList.value = [];
    }
  } catch (error) {
    console.log(error);
  }
}
</script>

<style scoped>
.NavigationBar {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}

.leftNavigation {
  width: 16rem;
  height: 100%;
  background-color: #1d2022;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
}

.ShowBtn {
  position: fixed;
  left: 0;
  /* 或根据需要调整 */
  top: 0;
  z-index: 110;
  color: #aaaaaa;
  font-size: 2rem;
  padding: 0.25rem;
}

.ShowBtn:hover {
  color: #1d2022;
}

.No-edit {
  display: flex;
  flex-direction: row;
}

.In-edit {
  display: flex;
  flex-direction: row;
}

.Edit-btn {
  width: 1.25rem;
  padding: 0.3rem 0.5rem 0.3rem 0.3rem;
  cursor: pointer;
}

.Del-btn {
  width: 1.25rem;
  padding: 0.3rem 0.3rem 0.3rem 0.5rem;
  cursor: pointer;
}

.headPortrait {
  display: block;
  margin: 3.125rem auto 0;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden !important;
  border-radius: 0.5rem;
}

.surplus {
  width: 100%;
  font-size: 1rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.surplus-title {
  width: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 2.5rem;
  font-weight: bold;
}

.wrapper {
  display: flex;
  flex: 1;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hideBtn {
  font-size: 2rem;
  color: whitesmoke;
  z-index: 110;
  position: fixed;
  padding: 0.25rem;
}

.hideBtn:hover {
  color: #464646;
}

.news-count {
  position: absolute;
  top: 0;
  padding: 0.05rem;
  width: 1rem;
  height: 1rem;
  text-align: center;
  background-color: red;
  border-radius: 5rem;
  z-index: 100;
}

.swing {
  animation: swing 0.5s infinite alternate;
}

.swing el-icon:hover {
  color: dark;
}

@keyframes swing {
  0% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(10deg);
  }
}

.rightContent {
  position: absolute;
  right: 0;
  height: 100%;
  width: calc(100% - 16rem);
  background: #ffffff;
  transition: all 0.25s;
  z-index: 100;
}

.isHiden .rightContent {
  width: 100%;
}

.header {
  margin-top: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.switch-button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 95%;
}

.switch-active {
  background-color: #464646;
  border-radius: 0.8rem;
  height: 2.5rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 0.625rem;
  text-decoration: none;
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 0.3rem;
  cursor: pointer;
}

.switch-item {
  height: 2.5rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 0.625rem;
  text-decoration: none;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 1rem;
  margin-bottom: 0.3rem;
  cursor: pointer;
}

.switch-item:hover {
  background-color: #464646;
}

.switch-item-title {
  margin-left: 0.6rem;
}

.Bottom-func {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 0.3rem;
}

.bottom-func-menu {
  display: flex;
  flex-direction: column;
  width: 95%;
  border-top: 0.05rem solid #ffffff;
  border-bottom: 0.05rem solid #ffffff;
  box-sizing: border-box;
  padding-bottom: 0.3rem;
  animation: slideInFromBottom 0.5s ease-out;
  background-color: #1d2022;
  z-index: 10;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.bottom-func-btn {
  display: flex;
  width: 100%;
  color: #ffffff;
  align-items: center;
  margin-top: 0.25rem;
  border-radius: 1rem;
  cursor: pointer;
}

.bottom-func-btn:hover {
  background-color: #464646;
}

.bottom-func-btn .el-icon {
  padding: 0.6rem;
}

.notice-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(50, 50, 50, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notice-content {
  display: flex;
  flex-direction: column;
  background-color: #1d2022;
  width: fit-content;
  border-radius: 1.25rem;
  padding: 1rem;
}

.close-btn {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
}

.notice-title {
  color: whitesmoke;
  font-size: 2rem;
  text-align: center;
  margin: 0.5rem;
}

.wxgzh-img {
  border-radius: 1rem;
  background-color: white;
  padding: 1rem;
  height: 20rem;
}

.rechargeButton {
  width: 95%;
  height: 2.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.25rem;
  color: #ffffff;
}

.login-func {
  display: flex;
  width: 95%;
  height: max-content;
  z-index: 100;
}

.header-user {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  width: 100%;
  padding: 0.25rem 0;
  border-radius: 1rem;
  margin-top: 0.25rem;
}

.header-user .el-avatar {
  margin: 0 0.6rem;
}

.header-user:hover {
  background-color: #464646;
}

.header-user-name {
  padding-left: 0.6rem;
  font-size: 1rem;
  width: 4.375rem;
  color: #ffffff;
}

.Color-btn {
  padding: 0 0.6rem;
  border-right: 0.05rem solid rgb(255, 255, 255);
  cursor: pointer;
}

.newchatbox {
  display: flex;
  justify-content: space-evenly;
  color: white;
  width: 100%;
}

.cache-btn {
  color: white;
  display: flex;
  padding-left: 0.6rem;
  align-items: center;
  border-radius: 0.875rem;
  border: 0.05rem solid #464646;
  box-sizing: border-box;
  height: 2.5rem;
  width: 94%;
  cursor: pointer;
  animation: slideDown 1s ease forwards;
}

.cache-btn:hover {
  background-color: #464646;
}

.cache-btn-text {
  padding-left: 0.6rem;
  font-size: 1rem;
}

.cache-content {
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  animation: slideDown 1s ease forwards;
}

@keyframes slideDown {
  from {
    transform: translateY(-5%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.cache-scrollbar {
  color: #b7b7b7;
  width: 95%;
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cache-padding {
  border-radius: 0.875rem;
}

.cache-padding-item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0.25rem 0 0.25rem 0;
  width: 100%;
  height: 3.125rem;
  border-radius: 0.875rem;
  margin-bottom: 0.3rem;
  cursor: pointer;
}

.cache-padding-item:hover {
  background-color: #464646;
}

.cache-iamge {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.6rem;
  margin-left: 0.3rem;
}

.cache-message {
  width: 30%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  height: 3.125rem;
  padding-left: 0.6rem;
  justify-content: center;
}

.cache-message-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  color: #ffffff;
}

.cache-message-input {
  width: 100%;
  background: 0 0;
  font-size: 1rem;
  text-overflow: ellipsis;
  color: #ffffff;
  white-space: nowrap;
  border: none;
}

.cache-message-time {
  width: 100%;
  padding-top: 0.125rem;
  font-size: 0.6rem;
  color: #ffffff;
}

.cache-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 3.125rem;
}

.hidden {
  display: none;
}

.right-wrap {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  right: 0;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.25);
  display: none;
}

@media only screen and (max-width: 450px) {
  .leftNavigation {
    position: absolute;
    width: 16rem;
    z-index: 120;
    transition: all 0.5s;
  }

  .isHiden .leftNavigation {
    transform: translateX(-16rem);
    /* 向左移动自身的宽度，实现隐藏效果 */
  }

  .rightContent {
    position: relative;
    flex: 1;
  }

  .hideBtn {
    z-index: 1000;
  }

  .notice-title {
    font-size: 1rem;
  }

  .wxgzh-img {
    height: 6rem;
  }

  .right-wrap {
    display: flex;
  }
}
</style>
