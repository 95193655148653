// store.js
import { ref } from 'vue';
//共享对话缓存
export let dialogueCache = ref({});
//共享对话内容列表
export let conversationList = ref([]);
//共享图像生成缓存
export let imageCache = ref({});
export let ImageConversationList = ref([]);

//图像生成历史记录
export let srcList = ref({
    index: 0,
    array: ['https://s3.bmp.ovh/imgs/2024/01/03/c8ae1fa48a1b8356.png']
});
export let showUrl = ref('')
// 正在生成图像
export let isGeneratingImage = ref(false)
// 正在生成文本
export let isGeneratingChat = ref(false)

export let AcademicChoseplaceholder = ref('请直接输入Python代码，AI将帮你运行！')