<template>
  <el-dialog
    v-model="dialogVisible"
    width="90%"
    class="login-dialog"
    :show-close="false"
    align-center
    @close="close"
    style="border-radius: 1rem; overflow-x: hidden"
    v-if="!isForget"
  >
    <div class="login-box">
      <div class="login-title">
        <!-- <div
          :class="loginType === 0 ? 'login-selected' : ''"
          @click="switchLoginType(0)"
        >
          微信登录
        </div> -->
        <div
          :class="loginType === 1 ? 'login-selected' : ''"
          @click="switchLoginType(1)"
        >
          {{ isLogin ? "邮箱登录" : "注册账号" }}
        </div>
        <!-- <div @click="wechatAuthLogin()">微信授权登录</div> -->
      </div>
      <!--微信扫码登录-->
      <!-- <div v-if="loginType === 0">
        <div class="form">
          <div class="animation" v-if="!qrCodeLoaded">
            <view class="loading-model">
              <view class="loader"></view>
            </view>
          </div>
          <div class="content" v-if="qrCode">
            <div style="position: relative; border-radius: 5px">
              <img :src="qrCode" class="qc_code" alt="二维码" />
              <div class="cover-div" v-if="isFailure">二维码已失效</div>
            </div>
          </div>
        </div>
        <div class="btn-generate" v-if="isFailure">
          <el-button type="primary" color="#626aef" @click="getLoginQRCode()"
            >重新生成</el-button
          >
        </div>
        <div class="h5 prompt-style" v-if="!loginAnimation">正在加载中...</div>
        <div class="h5 prompt-style" v-if="!promptAnimation">
          使用微信扫一扫快速登录后使用
        </div>
      </div> -->
      <!--登录-->
      <div
        v-if="true"
        style="margin-top: 2.5rem; padding: 0 0.75rem 3.125rem"
      >
        <el-form @keyup.enter="onSubmit" ref="formRef" size="large">
          <el-form-item prop="username">
            <el-input
              type="text"
              clearable
              v-model="emailForm.email"
              placeholder="请输入邮箱"
              autocomplete="“off”"
            >
              <template #prefix>
                <el-icon :size="16" color="var(&#45;&#45;el-input-icon-color)">
                  <UserFilled />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="emailForm.password"
              type="password"
              placeholder="请输入密码"
              show-password
              autocomplete="off"
            >
              <template #prefix>
                <el-icon :size="16" color="var(&#45;&#45;el-input-icon-color)">
                  <Platform />
                </el-icon>
              </template>
            </el-input>
            <div class="RegisteBtn" @click="isLogin = !isLogin" v-if="isLogin">
              前往注册
            </div>
            <div
              class="RegisteBtn"
              v-if="isLogin"
              @click="isForget = true"
              style="margin-left: 0.6rem"
            >
              重设密码
            </div>
          </el-form-item>
          <el-form-item prop="code" v-show="isLogin === false">
            <el-input
              maxlength="6"
              minlength="6"
              ref="codeRef"
              type="text"
              clearable
              v-model="emailForm.code"
              placeholder="请输入验证码"
              autocomplete="“off”"
            >
              >
              <template #prefix>
                <el-icon :size="16" color="var(&#45;&#45;el-input-icon-color)">
                  <Connection />
                </el-icon>
              </template>
              <template #append>
                <div style="padding-left: 0.6rem; background: none">
                  <el-button
                    :disabled="disabled"
                    @click="startCountdown"
                    v-text="buttonText"
                    style="color: #ffffff"
                  ></el-button>
                </div>
              </template>
            </el-input>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 18.75rem;
              "
            >
              <div>
                <input
                  type="checkbox"
                  v-model="agreedToDisclaimer"
                  style="color: #ffffff"
                />
                我同意
                <a
                  href="https://truescienceai.com/disclaimer.html"
                  target="_blank"
                  style="color: white"
                  >《网站协议》</a
                >
              </div>
              <div
                style="
                  color: #1d2022;
                  font-size: 0.75rem;
                  background-color: white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 1.56rem;
                  width: 6.25rem;
                  border-radius: 0.875rem;
                "
                @click="isLogin = !isLogin"
                v-if="!isLogin"
              >
                前往登录
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loginLoading"
              class="submit-button"
              round
              type="primary"
              size="large"
              @click="onSubmit"
            >
              {{ isLogin ? "快速登录" : "快速注册" }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
  <!-- 重设密码 -->
  <div class="showlogin" v-if="isForget">
    <div class="rightcontent">
      <div class="close-btn">
        <el-icon
          size="30"
          style="padding: 1rem"
          @click="(isForget = false), (isLogin = true)"
        >
          <DArrowLeft />
        </el-icon>
      </div>
      <div class="func-bar">
        <div class="func-bar-title">账号</div>
        <el-input
          class="input-box"
          v-model="IDInput"
          placeholder="请输入邮箱"
          clearable
        />
      </div>
      <div class="func-bar">
        <div class="func-bar-title">密码</div>
        <el-input
          class="input-box"
          v-model="passwordsInput"
          placeholder="请输入密码"
          show-password="true"
        />
      </div>
      <div class="func-bar">
        <div class="func-bar-title">验证码</div>
        <el-input
          class="input-box"
          v-model="checkcodeInput"
          placeholder="请输入验证码"
          style="width: 30%"
        />
        <div class="get" style="margin-left: 10%" @click="GetCheckCode">
          获取
        </div>
      </div>
      <div
        class="func-bar"
        style="width: 100%; align-items: center; justify-content: center"
      >
        <div
          class="login"
          style="display: flex; align-items: center; justify-content: center"
          @click="ChangePassword()"
        >
          确 认 修 改
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

import {
  EmailEnroll,
  EmailLogin,
  getEmailCode,
  GetUserInfo,
  GetWechatCode,
  ByCode,
  isQrCodeLoginSucceed,
} from "../../api/BSideApi";
import { ElMessage, ElNotification } from "element-plus";
import store from "@/store";
import { Connection, Platform, UserFilled } from "@element-plus/icons-vue";
import axios from "axios";

export default defineComponent({
  name: "LoginDialog",
  components: { Connection, Platform, UserFilled },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //this.getCode();
    this.get_userinfo();
  },
  methods: {
    async get_userinfo() {
      const code = this.getUrlCode().code;
      if (code) {
        const promise = await ByCode(code);
        console.log(promise);

        if (promise) {
          localStorage.setItem("token", promise);
          try {
            let res = await GetUserInfo();
            store.commit("setUserinfo", res);
            console.log(res);
          } catch (e) {
            console.log(e);
          }
          ElNotification({
            title: "登录成功",
            message: "快登录体验True Science AI吧",
            type: "success",
          });
          // 将邮箱储存在缓存
          localStorage.setItem("email", promise);
          console.log(localStorage.getItem("邮箱"));
          location.reload();
        }
      }
    },

    // 截取url中的code方法
    getUrlCode() {
      var url = location.search;
      var theRequest = {};
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = decodeURIComponent(
            strs[i].split("=")[1]
          );
        }
      }
      return theRequest;
    },
  },

  setup(props, { emit }) {
    let IDInput = ref("");
    let passwordsInput = ref("");
    // 获取验证码
    let checkCode = ref("");
    async function GetCheckCode() {
      ElNotification({
        message: "发送成功",
        type: "success",
      });
      // 如果账号和密码输入不为空
      if (IDInput.value && passwordsInput.value) {
        // 将用户账号发送到后端，用于发送邮件
        const data = {
          userEmail: IDInput.value,
        };
        try {
          const response = await axios.post(
            'https://zh.node.truescience.cn/admin/func/getcheckcode',
            data
          );
          checkCode.value = response.data;
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("请输入邮箱或者密码");
        ElNotification({
          message: "请输入邮箱或者密码",
          type: "error",
        });
      }
    }
    let checkcodeInput = ref("");
    // 确认修改密码
    async function ChangePassword() {
      // 如果邮箱和密码都输入了
      if (IDInput.value && passwordsInput.value) {
        // 检查验证码是否输入正确
        if (checkcodeInput.value != checkCode.value) {
          ElNotification({
            message: "验证码错误",
            type: "error",
          });
          return;
        }
        // 将用户账号发送到后端，用于发送邮件
        const data = {
          userID: IDInput.value,
          userPassword: passwordsInput.value,
        };
        try {
          const response = await axios.post(
            'https://zh.node.truescience.cn/admin/func/changepassword',
            data
          );
          if (response.data == "success") {
            ElNotification({
              message: "密码重设成功",
              type: "success",
            });
            isForget.value = false;
          } else {
            ElNotification({
              message: "该邮箱没有注册",
              type: "error",
            });
            console.log(response.data);
          }
        } catch (error) {
          console.log(error);
          ElNotification({
            message: "服务器出错!",
            type: "error",
          });
        }
      } else {
        ElNotification({
          message: "请输入邮箱和密码",
          type: "error",
        });
      }
    }

    let isForget = ref(false);
    const agreedToDisclaimer = ref(false);
    const buttonText = ref("获取验证码");
    let loginType = ref(0);
    let qrCode = ref("");
    let qrCodeLoaded = ref(false);
    let promptAnimation = ref(true);
    let loginAnimation = ref(false);
    let loginLoading = ref(false);
    let dialogVisible = ref(false);
    let isLogin = ref(true);
    // eslint-disable-next-line no-unused-vars
    let verifyCode = ref("");
    let ticket = ref("");
    let isFailure = ref(false);
    let timerId;
    let lock = ref(false);
    const countdown = ref(null);
    const disabled = ref(false);
    const isCode = ref(true);
    const emailForm = ref({
      email: "",
      password: "",
      code: "",
    });
    watch(
      () => props.show,
      (newValue) => {
        if (newValue) {
          getLoginQRCode();
          dialogVisible.value = true;
        }
      },
      {
        immediate: true,
      }
    );

    function switchLoginType(type) {
      if (type === 0) {
        getLoginQRCode();
      } else {
        if (timerId) {
          clearInterval(timerId);
        }
      }
      loginType.value = type;
    }

    async function wechatAuthLogin() {
      // 构建微信授权URL，需要替换YOUR_APP_ID和REDIRECT_URL为你的实际值
      //const wechatAuthUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe3136aa57a0b0f18&redirect_uri=https://www.babibala.site&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
      // 使用window.location.href进行页面跳转
      //window.location.href = wechatAuthUrl;

      // 获取页面url
      var local = window.location.href;
      var appid = "wxc32033941490ffe0";
      // 重定向地址
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        local
      )}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
    }

    /**
     * 获取验证码
     * @returns {Promise<void>}
     */
    async function startCountdown() {
      if (agreedToDisclaimer.value) {
        if (isCode.value) {
          if (!emailForm.value.email) {
            ElMessage.warning("验证邮箱不能为空");
            return;
          }
          isCode.value = false;
          let seconds = 120;
          try {
            buttonText.value = "正在发送中";
            await getEmailCode(emailForm.value.email);
            ElMessage.info("验证码发送成功");
            disabled.value = true;
          } catch (e) {
            ElNotification({
              title: "错误",
              message: e,
              type: "error",
            });
            buttonText.value = "重新获取验证码";
            isCode.value = true;
            return;
          }
          countdown.value = setInterval(() => {
            if (seconds === 0) {
              clearInterval(countdown.value);
              countdown.value = null;
              disabled.value = false;
              buttonText.value = "重新获取验证码";
              isCode.value = true;
            } else {
              seconds--;
              buttonText.value = `${seconds}` + "后重新获取";
            }
          }, 1000);
        }
      } else {
        ElMessage.warning("请同意免责声明协议");
      }
    }

    /**
     * 获取登录二维码
     */
    async function getLoginQRCode() {
      try {
        // 微信登录基础url
        const url_pre = "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=";
        // 获取二维码
        const res = await GetWechatCode();
        console.log("44res", res);
        console.log("res.scene_id", res.scene_id);

        if (res.succ == "1") {
          // 将 isFailure 状态设置为 false，表示当前没有失败。
          isFailure.value = false;
          // 将响应中的 scene_id 赋值给 verifyCode，这通常是一个用于验证的唯一标识符。
          verifyCode.value = res.scene_id;
          // 将响应数据中的 ticket 赋值给 ticket 变量，这是生成二维码所需的票据。
          ticket.value = res.data.ticket;
          // 将 ticket 拼接到 url_pre 后面，形成完整的二维码 URL，并赋值给 qrCode。
          qrCode.value = url_pre + res.data.ticket;
          // 将 qrCodeLoaded 状态设置为 true，表示二维码已成功加载。
          qrCodeLoaded.value = true;
          // 将 loginAnimation 状态设置为 true，通常用于触发登录动画效果。
          loginAnimation.value = true;
          // 将 promptAnimation 状态设置为 false，通常用于停止某些提示动画效果。
          promptAnimation.value = false;
          // 设置一个定时器，每隔 5 秒调用一次 scanCodeLoginResults 函数，用于检查用户是否已扫码登录成功。
          timerId = setInterval(() => {
            scanCodeLoginResults();
          }, 5000);
          // 设置一个额外的定时器，1分钟后如果登录未成功，则isFailure.value = true
          setTimeout(() => {
            isFailure.value = true;
            clearInterval(timerId); // 清除定时器
          }, 60000); // 60000毫秒 = 1分钟
        }
      } catch (e) {
        console.log(e);
      }
    }

    /**
     * 扫码登录结果
     * @returns {Promise<void>}
     */
    async function scanCodeLoginResults() {
      try {
        let promise = await isQrCodeLoginSucceed(ticket.value);
        promise = JSON.parse(promise);
        console.log("获取到返回值：", promise);
        if (promise) {
          if (!lock.value) {
            lock.value = true;
            clearInterval(timerId);
            localStorage.setItem("token", promise.token);
            dialogVisible.value = false;
            loginLoading.value = false;
            try {
              let res = await GetUserInfo();
              store.commit("setUserinfo", res);
              console.log(res);
              // eslint-disable-next-line no-empty
            } catch (e) {
              console.log(e);
            }
            clearInterval(timerId);
            // 将主键存在缓存中
            const userInfo = {
              userID: promise.userId,
            };
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            ElNotification({
              title: "登录成功",
              message: "欢迎使用True Science AI",
              type: "success",
            });
            emit("loginSucceeded");
            location.reload();
            lock.value = false;
          }
        }
      } catch (e) {
        //  clearInterval(timerId);
        //  isFailure.value = true
        console.log(e);
      }
    }

    function close() {
      emit("close");
    }

    /**
     * 邮箱登录
     * @returns {Promise<void>}
     */
    async function emailLogin() {
      if (loginLoading.value) {
        return;
      }
      let value = emailForm.value;
      if (!value.email) {
        ElMessage.warning("登录邮箱不能为空");
        return;
      }
      if (!value.password) {
        ElMessage.warning("登录邮箱不能为空");
        return;
      }
      loginLoading.value = true;
      try {
        let promise = await EmailLogin(emailForm.value);
        localStorage.setItem("token", promise);
        try {
          let res = await GetUserInfo();
          store.commit("setUserinfo", res);
          console.log(res);
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.log(e);
        }
        dialogVisible.value = false;
        loginLoading.value = false;
        // 通过邮箱获取主键
        const data = {
          userEmail: emailForm.value.email,
        };
        const response = await axios.post(
          `${process.env.VUE_APP_ZH_NODE_BASE_URL}/admin/func/GetUserIDFromEmail`,
          data
        );
        const userInfo = {
          userID: response.data[0].user_id,
          isVIP: response.data[0].Is_VIP,
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        ElNotification({
          title: "登录成功",
          message: "快登录体验True Science AI吧",
          type: "success",
        });
        loginLoading.value = false;
        isLogin.value = true;
        location.reload();
      } catch (e) {
        ElMessage.error(e);
        loginLoading.value = false;
      }
    }

    async function register() {
      try {
        if (!emailForm.value.email) {
          ElMessage.warning("注册邮箱不能为空");
          return;
        }
        if (!emailForm.value.password) {
          ElMessage.warning("登陆密码不能为空");
          return;
        }
        if (!emailForm.value.code) {
          ElMessage.warning("验证码不能为空");
          return;
        }
        loginLoading.value = true;
        await EmailEnroll(emailForm.value);
        ElNotification({
          title: "注册成功",
          message: "快登录体验AI功能吧",
          type: "success",
        });
        loginLoading.value = false;
        isLogin.value = true;
      } catch (e) {
        loginLoading.value = false;
        ElNotification({
          title: "错误",
          message: e,
          type: "error",
        });
      }
    }

    function onSubmit() {
      if (isLogin.value) {
        emailLogin();
      } else {
        register();
      }
    }

    return {
      IDInput,
      passwordsInput,
      isForget,
      checkCode,
      GetCheckCode,
      checkcodeInput,
      ChangePassword,
      onSubmit,
      startCountdown,
      buttonText,
      countdown,
      qrCode,
      qrCodeLoaded,
      loginAnimation,
      promptAnimation,
      loginLoading,
      dialogVisible,
      close,
      isLogin,
      getLoginQRCode,
      scanCodeLoginResults,
      wechatAuthLogin,
      isFailure,
      loginType,
      switchLoginType,
      emailForm,
      disabled,
      agreedToDisclaimer,
    };
  },
});
</script>

<style scoped>
.animation {
  width: 10.75rem;
  height: 10.75rem;
  overflow: hidden;
  margin: 1.25rem auto 0;
  background-color: rgb(53, 57, 60);
}

.animation img {
  width: 100%;
  height: 100%;
  object-fit: none;
}

.RegisteBtn {
  text-align: right;
  color: #1d2022;
  font-size: 0.75rem;
  background-color: white;
  border-radius: 0.875rem;
  margin-top: 0.6rem;
  width: 6.25rem;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 1.56rem;
}

.h5 {
  font-size: 0.6rem;
}

@media (max-width: 48rem) {
  .h5 {
    font-size: 0.75rem;
  }
}

.login-box {
  overflow: hidden;
  width: 100%;
  padding: 0;
  background-color: rgb(29, 32, 34);
}

.cover-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(27, 30, 32, 0.8);
  padding: 0.6rem;
  border-radius: 0.3rem;
  width: 13.125rem;
  height: 13.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: white;
}

.login-box > .head > img {
  display: block;
  width: 100%;
  margin: 0 auto;
  user-select: none;
  margin-bottom: -1.25rem;
}

.head_img {
  background-image: linear-gradient(
      to top,
      rgb(29, 32, 34) 30%,
      transparent 100%
    ),
    url("../assets/login-header.png");
  background-size: cover;
  background-position: center;
  height: 6.25rem;
  box-shadow: 0 -0.6rem 1.25rem rgba(27, 30, 32, 0.2);
}

.login-dialog {
}

.form {
  position: relative;
  padding: 0.6rem;
}

.submit-button {
  width: 100%;
  letter-spacing: 0.125rem;
  font-weight: 300;
  margin-top: 1rem;
  --el-button-bg-color: #ffffff;
  border: none;
  color: #1d2022;
}

.submit-button:hover,
.submit-button:focus,
.submit-button:active {
  background-color: #ffffff;
  outline: 0;
}

.form > .content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader:before {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #c2a5ff;
  animation: preloader_4_before 1.5s infinite ease-in-out;
}

.loader:after {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #ffa4c8;
  left: 0.3rem;
  animation: preloader_4_after 1.5s infinite ease-in-out;
}

@keyframes preloader_4_before {
  0% {
    transform: translateX(0) rotate(0deg);
  }

  50% {
    transform: translateX(1rem) scale(1.2) rotate(260deg);
    background: #cca7f1;
    border-radius: 0;
  }

  100% {
    transform: translateX(0) rotate(0deg);
  }
}

@keyframes preloader_4_after {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-1rem) scale(1.2) rotate(-260deg);
    background: #9dbefa;
    border-radius: 0;
  }

  100% {
    transform: translateX(0);
  }
}

.loading-model {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.75rem;
  height: 10.75rem;
}

.login-title {
  text-align: center;
  font-size: 30;
  font-weight: 500;
  padding-bottom: 0.6rem;
  color: #b3b3b3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.6rem;
  padding-top: 3.125rem;
}

.login-selected {
  color: white;
  font-weight: 550;
}

.login-title div {
  margin: 0 1.25rem;
}

.qc_code {
  width: 12.5rem;
  height: 12.5rem;
  padding: 0.6rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 3.125rem rgba(183, 156, 156, 0.9);
}

.btn-generate {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.25rem;
}

.prompt-style {
  text-align: center;
  padding-top: 30;
  padding-bottom: 3.125rem;
}

/deep/ .el-input__inner {
  background: #ffffff !important;
  padding-left: 0.6rem;
  font-weight: 400;
  color: #1d2022;
}

/deep/ .el-input__wrapper {
  background: #ffffff;
  border-radius: 0.875rem;
}

/deep/ .el-input-group__append,
.el-input-group__prepend {
  border: none !important;
}

.showlogin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(52, 52, 54, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightcontent {
  color: white;
  background-color: #1d2022;
  border-radius: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  animation: slideInFromLeft 0.5s ease-out;
  z-index: 10;
  padding-bottom: 30;
}

.close-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.func-bar {
  display: flex;
  width: 100%;
  height: 8%;
  align-items: center;
  margin: 1rem 0;
  font-size: 1.25rem;
}

.func-bar-title {
  color: white;
  height: 100%;
  width: 25%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.get {
  background-color: white;
  color: #1d2022;
  padding: 0.3rem 1.25rem;
  border-radius: 1.25rem;
  width: 10%;
  text-align: center;
}

.login {
  background-color: white;
  color: #1d2022;
  padding: 0.3rem 1.25rem;
  border-radius: 1.25rem;
  width: 40%;
  text-align: center;
  cursor: pointer;
}

.input-box {
  width: 60%;
  height: 100%;
  font-size: 1rem;
}
</style>
