import {createRouter, createWebHashHistory} from 'vue-router'
import {cancelArr} from "@/utils/BSideRequest";

const routes = [
    {
        path: '/',
        name: 'chat',
        component: () => import('../views/DialogueView.vue'),
        meta: {
            title: 'AI问答', // TODO 浏览器标题
            isHeadNavigation: true, // TODO 是否显示tab
            keepAlive: true
        }
    },
    {
        path: '/picture',
        name: 'picture',
        component: () => import('../views/PictureView.vue'),
        meta: {
            title: 'AI绘图',
            isHeadNavigation: true,
            keepAlive: true
        }
    },
    {
        path: '/tts',
        name: 'tts',
        component: () => import('../views/TTSView.vue'),
        meta: {
            title: 'AI语音',
            isHeadNavigation: true,
            keepAlive: true
        }
    },    
    {
        path: '/price',
        name: 'price',
        component: () => import('../views/PriceView.vue'),
        meta: {
            title: '价格查看',
            isHeadNavigation: true,
            keepAlive: true
        }
    },    
    {
        path: '/prompt',
        name: 'prompt',
        component: () => import('../views/Admin/components/PromptView.vue'),
        meta: {
            title: '提示词',
            isHeadNavigation: true,
            keepAlive: false
        }
    },    
    {
        path: '/academic',
        name: 'academic',
        component: () => import('../views/AcademicView.vue'),
        meta: {
            title: 'AI学术',
            isHeadNavigation: true,
            keepAlive: false
        }
    },
    {
        path: '/purchase',
        name: 'Purchase',
        component: () => import('../views/PurchaseView.vue'),
        meta: {
            title: '购买中心',
            isHeadNavigation: true,
            keepAlive: false
        }
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('../views/OrdersView.vue'),
        meta: {
            title: '打赏记录',
            isHeadNavigation: true,
            keepAlive: false
        }
    },
    {
        path: '/collection',
        name: 'Collection',
        component: () => import('../views/CollectionView.vue'),
        meta: {
            title: '回答收藏',
            isHeadNavigation: true,
            keepAlive: false
        }
    },
    {
        path: '/indicator',
        name: 'Indicator',
        component: () => import('../views/InputCollectionView.vue'),
        meta: {
            title: '指令收藏',
            isHeadNavigation: true,
            keepAlive: false
        }
    },
    {
        path: "/news",
        name: "News",
        component: () => import('../views/NewsView.vue'),
        meta: {
            title: '消息中心',
            isHeadNavigation: false,
            keepAlive: false
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('@/views/Admin/AdminView.vue'),
        meta: {
            title: '管理控制台',
            isHeadNavigation: false,
            keepAlive: true
        }
    },
    {
        path: '/CodeInterpreter',
        name: 'CodeInterpreter',
        component: () => import('@/views/CodeInterpreterView.vue'),
        meta: {
            title: '代码解释器',
            isHeadNavigation: false,
            keepAlive: true
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})


// TODO 全局前置守卫
router.beforeEach(async (to) => {
    // TODO 页面切换中断所有请求
    cancelArr.forEach((cancel, index) => {
        cancel()
        cancelArr.splice(index, 1)
    })

    // TODO 设置浏览器Title
    document.title = '真智AI - ' + (to.meta.title ? to.meta.title : '')
})

export default router
