<template>
    <div class="body">
        <div class="funt-box">
            <!-- 图标 -->
            <div style="display: flex;">
                <font-awesome-icon icon="graduation-cap" color="white" style="font-size: 1.25rem; margin: 0.6rem;" />
                <p class="funt-title">学习工作</p>
            </div>
            <div class="func-content">
                <div class="boder-line"></div>
                <div class="func-list-box">
                    <div class="func-list" v-for="(items, index) in funcListAll" :key="index"
                        @click="setData(items.prompt, items.placeholder), switchFunc(items.id)"
                        :style="{ backgroundColor: academicChoseID == items.id ? '#464646' : '' }">
                        <div style="width: 3.125rem; display: flex; align-items: center; justify-content: center;">
                            <font-awesome-icon :icon="items.icon" color="white" style="font-size: 1.25rem;" />
                        </div>
                        <p class="func-list-title">{{ items.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="funt-box">
            <!-- 图标 -->
            <div style="display: flex;">
                <font-awesome-icon icon="seedling" color="white" style="font-size: 1.25rem; margin: 0.6rem;" />
                <p class="funt-title">农学</p>
            </div>
            <div class="func-content">
                <div class="boder-line"></div>
                <div class="func-list-box">
                    <div class="func-list" v-for="(items, index) in Agronomy" :key="index"
                        @click="setData(items.prompt, items.placeholder), switchFunc(items.id)"
                        :style="{ backgroundColor: academicChoseID == items.id ? '#464646' : '' }">
                        <div style="width: 3.125rem; display: flex; align-items: center; justify-content: center;">
                            <font-awesome-icon :icon="items.icon" color="white" style="font-size: 1.25rem; margin: 0.6rem;" />
                        </div>
                        <p class="func-list-title">{{ items.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="funt-box">
            <!-- 图标 -->
            <div style="display: flex;">
                <font-awesome-icon icon="oil-well" color="white" style="font-size: 1.25rem; margin: 0.6rem;" />
                <p class="funt-title">工学</p>
            </div>
            <div class="func-content">
                <div class="boder-line"></div>
                <div class="func-list-box">
                    <div class="func-list" v-for="(items, index) in Engineering" :key="index"
                        @click="setData(items.prompt, items.placeholder), switchFunc(items.id)"
                        :style="{ backgroundColor: academicChoseID == items.id ? '#464646' : '' }">
                        <div style="width: 3.125rem; display: flex; align-items: center; justify-content: center;">
                            <font-awesome-icon :icon="items.icon" color="white" style="font-size: 1.25rem; margin: 0.6rem;" />
                        </div>
                        <p class="func-list-title">{{ items.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="funt-box">
            <!-- 图标 -->
            <div style="display: flex;">
                <font-awesome-icon icon="landmark" color="white" style="font-size: 1.25rem; margin: 0.6rem;" />
                <p class="funt-title">文学</p>
            </div>
            <div class="func-content">
                <div class="boder-line"></div>
                <div class="func-list-box">
                    <div class="func-list" v-for="(items, index) in Literature" :key="index"
                        @click="setData(items.prompt, items.placeholder), switchFunc(items.id)"
                        :style="{ backgroundColor: academicChoseID == items.id ? '#464646' : '' }">
                        <div style="width: 3.125rem; display: flex; align-items: center; justify-content: center;">
                            <font-awesome-icon :icon="items.icon" color="white" style="font-size: 1.25rem; margin: 0.6rem;" />
                        </div>
                        <p class="func-list-title">{{ items.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="funt-box">
            <!-- 图标 -->
            <div style="display: flex;">
                <font-awesome-icon icon="atom" color="white" style="font-size: 1.25rem; margin: 0.6rem;" />
                <p class="funt-title">理学</p>
            </div>
            <div class="func-content">
                <div class="boder-line"></div>
                <div class="func-list-box">
                    <div class="func-list" v-for="(items, index) in Science" :key="index"
                        @click="setData(items.prompt, items.placeholder), switchFunc(items.id)"
                        :style="{ backgroundColor: academicChoseID == items.id ? '#464646' : '' }">
                        <div style="width: 3.125rem; display: flex; align-items: center; justify-content: center;">
                            <font-awesome-icon :icon="items.icon" color="white" style="font-size: 1.25rem; margin: 0.6rem;" />
                        </div>
                        <p class="func-list-title">{{ items.title }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

import { defineComponent, ref } from "vue";
import { funcListAll, Agronomy, Engineering, Literature, Science } from "../utils/AcademicData.js";
import { useStore } from 'vuex';
import { AcademicChoseplaceholder } from '@/store/store.js';

export default defineComponent({
    name: "AcademicBar",
    setup() {
        // // 监听路由变化
        // watch(() => router.currentRoute.value, (newValue) => {
        //     switchFunc('A1')
        //     setData(funcListAll[0].prompt, funcListAll[0].placeholder)
        // })
        // 发送选择功能id
        const store = useStore()
        function setData(prompt, placeholder) {
            store.commit('setAcademicChosePrompt', prompt);
            AcademicChoseplaceholder.value = placeholder;
        }

        let academicChoseID = ref('A1')
        function switchFunc(id) {
            academicChoseID.value = id;
        }

        return {
            setData, academicChoseID, switchFunc, AcademicChoseplaceholder,
            funcListAll, Agronomy, Engineering, Literature, Science
        }
    }
});
</script>
  
<style scoped>
.body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.funt-box {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 0.6rem;
    box-sizing: border-box;
    padding-left: 1.25rem;
}

.funt-title {
    color: white;
    font-size: 1rem;
    margin: 0.6rem 0;
    width: 100%;
}

.func-content {
    display: flex;
    align-items: center;
}

.boder-line {
    border: 0.05rem solid white;
    height: 100%;
}

.func-list-box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.func-list {
    display: flex;
    align-items: center;
    margin-bottom: 0.3125rem;
    border-radius: 0.875rem;
    margin-left: 0.6rem;
}

.func-list:hover {
    background-color: #464646;
}

.func-list-title {
    color: white;
    margin: 0.6rem 0;
    font-size: 1rem;
}</style>
  