import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import mitt from 'mitt'

// TODO ElementPlus
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/index.css';
import 'element-plus/theme-chalk/display.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faPersonChalkboard, faPenClip, faFilePen,
    faEnvelopeOpenText, faPersonRays,
    faPenToSquare, faGraduationCap, faAtom,
    faSeedling, faOilWell, faLandmark,
    faLaptopCode, faFlask, faNewspaper,
    faLanguage, faFileCode, faSquarePen,
    faCheckDouble, faBookQuran
} from '@fortawesome/free-solid-svg-icons';

// TODO v-md-editor（代码块）
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';

// TODO Prism
import Prism from 'prismjs';
// TODO 代码高亮
import 'prismjs/components/prism-json';
// TODO Copy Code 快捷复制代码
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
import './serviceWorker.js';
import './registerServiceWorker'

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('./serviceWorker.js').then(registration => {
        console.log('SW registered: ', registration);
      }).catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
    });
  }
  

VMdEditor.use(vuepressTheme, {
    Prism
});
VMdEditor.use(createCopyCodePlugin(), createMermaidPlugin());

// 导入图标
library.add(faPersonChalkboard, faPenClip, faFilePen,
    faEnvelopeOpenText, faPersonRays, faPenToSquare,
    faGraduationCap, faAtom, faSeedling,
    faOilWell, faLandmark, faLaptopCode,
    faFlask, faNewspaper, faLanguage, faFileCode,
    faSquarePen, faCheckDouble, faBookQuran);

let app = createApp(App);

// 全局注册 Font Awesome 图标组件
app.component('font-awesome-icon', FontAwesomeIcon);

// v-md-editor
app.use(VMdEditor);

// 路由导航钩子
router.afterEach(() => {
    if (window.uetq) {
        window.uetq.push('pageLoad');
    }
});

app.use(router);
app.use(store);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app');

// main.js
app.config.globalProperties.emitter = mitt() // 主要是这行