<template>
  <LeftNavigationBar />
  <el-dialog
    class="announcement"
    v-model="dialogVisible"
    center
    align-center
    width="380px"
    style="background-color: rgb(27, 30, 32)"
  >
    <span style="text-align: center">{{ context }}</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" color="#626aef">
          朕已阅
        </el-button>
      </span>
    </template>
  </el-dialog>
  <levitation-ball />
</template>

<script>
import { useStore } from "vuex";
import LeftNavigationBar from "@/components/LeftNavigationBar.vue";
import LevitationBall from "@/components/LevitationBall.vue";
import { getAnnouncement } from "../api/BSideApi";
import { onMounted, ref } from "vue";

export default {
  components: { LeftNavigationBar, LevitationBall },
  computed: {
  },
  setup() {
    let store = useStore();

    const dialogueCache = ref({});
    let conversationList = ref([]);
    let loginVisible2 = ref(false);

    store.commit("initState");
    const dialogVisible = ref(false);
    const context = ref("");
    onMounted(() => {
      //获取对话缓存数据
      let item = localStorage.getItem("dialogueCache");
      if (store.getters.userinfo) {
        if (!store.getters.userinfo) return (loginVisible2.value = true);
        if (item) {
          dialogueCache.value = JSON.parse(item);
          let value = dialogueCache.value;
          conversationList.value = value.array[value.index].context;
        } else {
          dialogueCache.value = {
            index: 0,
            array: [
              {
                title: "新创建的对话",
                time: Date.now(),
                context: conversationList.value,
              },
            ],
          };
          localStorage.setItem("dialogueCache", JSON.stringify(dialogueCache.value));
        }
      }

      setTimeout(() => {
        getAnnouncementData();
      }, 100);
    });

    async function getAnnouncementData() {
      try {
        let announcement = await getAnnouncement();
        if (announcement) {
          let item = localStorage.getItem("announcement");
          if (item !== null) {
            let parse = JSON.parse(item);
            if (parse.logotypeId !== announcement.logotypeId) {
              localStorage.setItem("announcement", JSON.stringify(announcement));
              context.value = announcement.context;
              dialogVisible.value = true;
            }
          } else {
            localStorage.setItem("announcement", JSON.stringify(announcement));
            context.value = announcement.context;
            dialogVisible.value = true;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    return {
      dialogVisible,
      context,
      dialogueCache,
      conversationList,
      loginVisible2,
    };
  },
};
</script>

<style>
.announcement {
  width: 25%;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .announcement {
    width: 70%;
  }
}

#app {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  font-family: var(--font-family);
  letter-spacing: 1px;
}
/* 全局定义字体 */
:root {
  --font-family: "Grenette", "QingYuan", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "WenQuanYi Micro Hei", sans-serif;
}

/* 自定义字体 */
@font-face {
  font-family: "QingYuan";
  src: url("./assets/fonts/晴圆.ttc") format("truetype");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+4E00-9FFF; /* 中日韩统一表意文字 */
}

@font-face {
  font-family: "Grenette";
  src: url("./assets/fonts/grenette-regular-pro.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+0020-007F; /* 基本拉丁字符 */
}

@media only screen and (max-width: 767px) {
  #app {
    padding: 0;
  }
}

@media only screen and (max-width: 450px) {
  html,
  body {
    font-size: 14px;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #1d2022;
  width: 100%;
  height: 100%;
}

/* TODO 滚动条样式*/
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}

.login-dialog > header {
  display: none;
}

.login-dialog > .el-dialog__body {
  padding: 0 !important;
}

.el-input-group__append {
  box-shadow: none !important;
}
.el-input-group__append {
  background: none !important;
}

.el-pagination .btn-prev,
.el-pagination .btn-next {
  background-color: rgb(35, 40, 42) !important;
}

.el-pagination .el-pager li:not(.active) {
  background-color: rgb(35, 40, 42) !important;
  color: darkgray !important;
}
</style>
