import { createStore } from 'vuex'
export default createStore({
    state: {
        userinfo: false,
        // 发送AI学术选中的ID
        academicChosePrompt: '',
    },
    getters: {
        userinfo: state => state.userinfo,
        // 在这里定义获取状态的方法
        getAcademicChosePrompt(state) {
            return state.academicChosePrompt;
        }
    },
    mutations: {
        logout(state) {
            state.userinfo = false
        },
        setUserinfo(state, info) {
            state.userinfo = info
            localStorage.setItem('user', JSON.stringify(info));
        },
        initState(state) {
            let token = localStorage.getItem('token')
            if (token) {
                let user = localStorage.getItem('user')
                state.userinfo = JSON.parse(user)
            }

        },
        setAcademicChosePrompt(state, data) {
            state.academicChosePrompt = data;
        }
    },
    actions: {},
    modules: {}
})
